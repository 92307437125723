<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- LOCATIONID -->
						<v-col cols="6">
							<pui-select
								:id="`locationid-movementsequencestep`"
								v-model="model"
								disabled="true"
								toplabel
								attach="movementsequencestep-locationid"
								:label="$t('form.movementsequencestep.locationid')"
								modelName="location"
								:modelFormMapping="{ id: 'locationid' }"
								:itemsToSelect="[{ id: model.locationid }]"
								itemValue="id"
								itemText="description"
								:order="{ description: 'asc' }"
								:fixedFilter="filterLocation"
								required
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- ARRIVALTIME -->
						<v-col cols="6">
							<pui-date-field
								:id="`arrivaltime-movementsequencestep`"
								v-model="model.arrivaltime"
								:label="$t('form.movementsequencestep.arrivaltime')"
								:disabled="model.actualdate"
								required
								toplabel
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- ACTUALDATE -->
						<v-col cols="6">
							<pui-date-field
								:id="`actualdate-movementsequencestep`"
								v-model="model.actualdate"
								:label="$t('form.movementsequencestep.actualdate')"
								:disabled="formDisabled"
								toplabel
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DELAYSECONDS -->
						<v-col cols="6">
							<pui-number-field
								:id="`delayseconds-movementsequencestep`"
								v-model="model.delayseconds"
								:label="$t('form.movementsequencestep.delayseconds')"
								disabled="true"
								toplabel
								maxlength="10"
							></pui-number-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'movementsequencestep-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'movementsequencestep'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
